<template>
  <XDateTimePicker
    :class="[$style.datePicker, !disabled && $style.disabled]"
    :max-date="maxDate !== undefined ? generateMaxDate : ''"
    :min-date="minDate !== undefined ? generateMinDate : ''"
    :no-value-to-custom-elem="true"
    :value="value"
    format="YYYY-MM-DD"
    input-style
    :locale="$i18n.locale"
    :clear-button="false"
    no-header
    only-date
    no-shortcuts
    range
    left
    :disabled="disabled"
    :data-testid="`${prefixDataTestid}Field`"
    @input="handleInput"
  >
    <XTooltip
      :class="$style.tooltip"
      placement="right"
      :disabled="!tooltip"
    >
      <template #activator>
        <XTextInput
          :label="label"
          :disabled="disabled"
          grouped
          :class="$style.input"
          :value="formattedValue"
          :placeholder="generatePlaceholder"
          :data-testid="`${prefixDataTestid}Input`"
          readonly
          full-width
        >
          <XButton
            v-if="hasValue"
            model="plain"
            :data-testid="`${prefixDataTestid}ClearButton`"
            @click.stop="emitClearDate"
          >
            <XIcon icon="times" />
          </XButton>
          <XButton
            model="plain"
            :data-testid="`${prefixDataTestid}DropdownButton`"
          >
            <XIcon icon="calendar-alt" />
          </XButton>
        </XTextInput>
      </template>
      {{ $t('layout.header.filter.filterDateRange.tooltip') }}
    </XTooltip>
  </XDateTimePicker>
</template>

<script>
import moment from 'moment';

export default {
  name: 'HeaderFilterDateRangePicker',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    prefixDataTestid: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: Number,
      default: undefined,
    },
    minDate: {
      type: Number,
      default: undefined,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedValue() {
      const { start, end } = this.value;
      const formattedStartDate = moment(start).format('DD/MM/YYYY');
      const formattedEndDate = end ? moment(end).format('DD/MM/YYYY') : formattedStartDate;
      return this.hasValue ? `${formattedStartDate} - ${formattedEndDate}` : '';
    },
    generateMaxDate() {
      return moment().add(this.maxDate, 'd').format('YYYY-MM-DD');
    },
    generateMinDate() {
      return moment().subtract(this.minDate, 'd').format('YYYY-MM-DD');
    },
    generatePlaceholder() {
      return this.disabled
        ? this.$t('layout.header.filter.disabled')
        : this.placeholder || this.$t('general.action.selectDots');
    },
    hasValue() {
      return Object.keys(this.value).length;
    },
  },
  methods: {
    handleInput(date) {
      this.$emit('input', date);
    },
    emitClearDate() {
      this.$emit('clearDate');
    },
  },
};
</script>

<style lang="scss" module>
.datePicker {
  &.disabled {
    cursor: pointer;
  }

  .input {
    min-height: 39px;

    input:read-only:not(:disabled) {
      background-color: var(--color-neutral-0);
    }
  }

  .tooltip {
    width: 100%;
  }

  .content {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>
